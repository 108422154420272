@font-face {
  src: url("assets/font/Pretendard-Regular.woff2");
  font-family: "Pretendard-Regular";
}

@font-face {
  src: url("assets/font/Pretendard-Bold.woff2");
  font-family: "Pretendard-Bold";
}

@font-face {
  src: url("assets/font/Pretendard-SemiBold.woff2");
  font-family: "Pretendard-SemiBold";
}

@font-face {
  src: url("assets/font/Pretendard-Medium.woff2");
  font-family: "Pretendard-Medium";
}

@font-face {
  font-family: "GANGWON_EDU_POWER";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEduPowerExtraBoldA.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NM_AGGRO";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CAFE24_CLASSIC_TYPE";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2210-2@1.0/Cafe24ClassicType-Regular.woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HEIR_OF_LIGHT";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/HeirofLightBold.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GHANA_CHOCOLATE";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ghanachoco.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CHOSUNILBO_NM";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/Chosunilbo_myungjo.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "JALNAN";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CAFE24_DANJUNGHAE";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Danjunghae.woff");
  font-weight: normal;
  font-style: normal;
}
